import React, { useState, useEffect } from "react";
import { Alert, Button, Disclaimer, PopupModal } from "../../components"
import { usePageLoadingContext } from "../../contexts/PageLoadingContext";
import { useClientConfigs } from "../../contexts/ClientConfigContext";
import { useSelector } from "react-redux";
import store, { RootState } from "../../store";
import { clearCache, updateQuote, updateQuoteResponse } from "../../store/quote/action";
import { useAdobeAnalytics } from "../../contexts/AdobeAnalyticsContext";
import { useApi } from "../../api";
import { BusinessKeywordModel, BUSINESS_KEYWORD_RESPONSE, InitBusinessKeywordModel } from "../../api/types/business-keyword-model";
import { BusinessKeywordAutoComplete } from "../../components/business-keyword-autocomplete";
import { API_ERROR_MESSAGE } from "../../api/launchpad-api";
import { SaveQuoteRequest, SAVE_QUOTE_RESPONSE } from "../../api/types";
import useDevice from "../../hooks/useDevice";
import { CarrierRecommendationModel, CARRIER_RECOMMENDATION_RESPONSE, InitCarrierRecommendationModel } from "../../api/types/carrier-recommendation-model";

export const BusinessKeyword = () => {
    const api = useApi();    
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ title: "", message: "" });
    const [formError, setFormError] = useState(false);
    const quote = useSelector((state: RootState) => state.quote.quote);   
    const { showLoader, hideLoader } = usePageLoadingContext(); 
    const deviceDetail = useDevice();  
    const [keywordError, setKeywordError] = useState(""); 
    const [keywordList, setkeywordList] = useState<BusinessKeywordModel[]>([]);   
    const [selectedKeyword, setSelectedKeyword] = useState <BusinessKeywordModel | null>(InitBusinessKeywordModel);
    const [carrierRecommendation, setCarrierRecommendation] = useState<CarrierRecommendationModel>(InitCarrierRecommendationModel);

    useEffect(() => {
        showLoader();

       // setGeicoDtm({ ...geicoDtm, viewName: GeicoPageNames.Launchpad });

        var selectedProducts = quote.data.SelectedProducts;
        var lobCode = selectedProducts && selectedProducts.length > 0 ? selectedProducts[0].LobCode : "";

        if (lobCode && lobCode.length > 1) {
            api.commercialAgentApi.getBusinessKeywords(lobCode).then((result) => {
                if (result.type === BUSINESS_KEYWORD_RESPONSE) {
                    setkeywordList(result.data);
                }
                else {
                    window.scrollTo(0, 0);
                    setShowAlert(true);
                    setAlertMessage({
                        title: "Thank you for your request.",
                        message: API_ERROR_MESSAGE
                    });
                }
                setFormError(false);
                hideLoader();
            }, (error) => {
                console.error(error);
                hideLoader();
            });
        }

    }, []);
    
    useEffect(() => {
        if (selectedKeyword && selectedKeyword.id !== "") {
            showLoader();

            let saveQuote: SaveQuoteRequest = {
                ...quote.data,
                BusinessKeyword: selectedKeyword,
                CarrierDetail:null
            };

            api.commercialAgentApi.getCarrierRecommendation(saveQuote)
                .then((response) => {
                    hideLoader();
                    if (response.type === CARRIER_RECOMMENDATION_RESPONSE) {
                        
                        if (response.data.carrier?.toUpperCase() === "BOLDPENGUIN" && response.data.businessClass === null) {
                            setCarrierRecommendation({ ...response.data, businessClass: selectedKeyword.description });
                        }
                        else {
                            setCarrierRecommendation(response.data);
                        } 
                        setFormError(false);
                    }
                    else {
                        window.scrollTo(0, 0);
                        setShowAlert(true);
                        setCarrierRecommendation(InitCarrierRecommendationModel);
                        setAlertMessage({
                            title: "Thank you for your request.",
                            message: API_ERROR_MESSAGE
                        });                       
                    }
                }, (error) => {
                    console.log(error);
                    setShowAlert(true);
                    setCarrierRecommendation(InitCarrierRecommendationModel);
                    setAlertMessage({
                        title: "Thank you for your request.",
                        message: API_ERROR_MESSAGE
                    });
                    hideLoader();
                });
        }
        else {
            setCarrierRecommendation(InitCarrierRecommendationModel);
        }
    }, [selectedKeyword]);

    const isNextButtonDisabled = (): boolean => {
        if (carrierRecommendation && carrierRecommendation.businessClass ) {
            return false;
        }
        return true;
    }

    function clearAlertMessage() {
        setAlertMessage({
            title: "",
            message: ""
        });
    }
   
    function SubmitForm(): void {
        
        showLoader();

        let saveQuote: SaveQuoteRequest = {
            ...quote.data,
            BusinessKeyword: selectedKeyword,
            CarrierDetail: [{
                CarrierCob: carrierRecommendation.businessClass,
                CarrierCode: carrierRecommendation.carrier,
                CarrierName: carrierRecommendation.carrier?.toUpperCase(),
            }]
        };

        api.commercialAgentApi.saveQuote(saveQuote, true)
            .then((quoteResponse) => {
                if (quoteResponse.type !== SAVE_QUOTE_RESPONSE) {
                    window.scrollTo(0, 0);
                    setShowAlert(true);
                    setAlertMessage({
                        title: "Thank you for your request.",
                        message: API_ERROR_MESSAGE
                    });
                    hideLoader();
                    return;
                }
                else {
                    store.dispatch(clearCache());
                    window.open(quoteResponse.data.redirectUrl, "_self");
                }
                setFormError(false);

            }, (error) => {                
                setShowAlert(true);
                setAlertMessage({
                    title: "Thank you for your request.",
                    message: API_ERROR_MESSAGE
                });
                hideLoader();
            });    
    }

    return (
        <div style={{ alignItems: "center", padding: "1rem", width: "95%" }} className="container">
            <div className="row">
                <div className="col-sm-12 col-md-12">
                    {(showAlert && (alertMessage.title || alertMessage.message) &&
                        <Alert
                            title={alertMessage.title}
                            content={alertMessage.message}
                            closeAlert={() => clearAlertMessage()}
                        />
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12">
                    <h2 className="page-header-left" style={{ padding: "1rem", marginBottom: "1rem" }}>
                        Tell us about your business.
                    </h2>
                </div>
            </div>                     
            {/* {showConsent()}           */}
            <div className="row" >
                <div className="col-sm-12 col-md-12">
                    <BusinessKeywordAutoComplete
                        disabled={false}
                        label="What does your business do?"
                        placeholder="Example(s) contractor, painter etc"
                        type="text"
                        defaultValue={quote.data.BusinessKeyword?.id ?? ""}
                        suggestions={keywordList}
                        onItemChange={(selectedItem) => { setKeywordError(""); setSelectedKeyword(selectedItem) }}
                        error={keywordError} />
                </div>
            </div>
            <div className="row mt-10" >
                <div className="col-sm-12 col-md-6">
                    <Button style={{ display: "none" }}
                        className="btn--secondary nav-button-back mt-10 w-3/5 xs:w-full sm:w-full"                       
                        onClick={(e) => window.history.go(-1)}
                        >Back</Button>
                </div>

                <div className="col-sm-12 col-md-6">
                    <Button disabled={isNextButtonDisabled()}
                        className="btn--primary nav-button-next mt-10  w-3/5 xs:w-full sm:w-full"                        
                        onClick={(e) => SubmitForm()}
                    >Continue</Button>
                </div>
            </div>
            <div className="row" >
                <div className="col-sm-12 col-md-12" style={{ marginBottom: "40px" }}>
                    <Disclaimer
                        headerLabel="Legal Notices"
                        label="The information you provide will be shared with selected insurance companies to obtain a policy. If GEICO is unable to match you with one of our trusted partners, you may opt to be referred to Commercial Insurance Center (&#8220;CIC&#x201C;), to help find a commercial insurance solution for your business. Your information will not be shared with CIC without your consent." />
                </div>
                <div className="col-sm-12 col-md-12">
                </div>
            </div>
        </div>
    );
};
