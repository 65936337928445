export const CLIENT_CONFIG_RESPONSE = "CLIENT_CONFIG_RESPONSE"

export interface ClientConfigData {
    adobeAnalyticsUri: string;    
    configLoaded: boolean;
    geicoAppUrl: string;
    userType: string,
    adClientId: string,   
    isAutoRedirectEnabled: boolean;
    isMultiplePartnerRecommendationEnabled: boolean;
    isBusinessKeywordRecommendationEnabled: boolean;
    isRecallEnabled: boolean;
    isUniversalKeywordEnabled: boolean;
    adInstance: string;
    graphUrl: string;
    cauDisabledState: string[];
}

export const defaultConfig: ClientConfigData = {
    adobeAnalyticsUri: "",   
    configLoaded: false,
    geicoAppUrl: "https://www.geico.com",    
    isAutoRedirectEnabled: false,
    isMultiplePartnerRecommendationEnabled: false,
    isBusinessKeywordRecommendationEnabled:false,
    isRecallEnabled: false,
    isUniversalKeywordEnabled: false,
    userType: "",
    adClientId: "",
    adInstance: "",
    graphUrl: "",
    cauDisabledState: []
}

export interface ClientConfigResponse {
    type: typeof CLIENT_CONFIG_RESPONSE;
    data: ClientConfigData
}
