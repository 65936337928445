import { CommercialProduct } from './types/product-config-settings';
import CommercialProducts from './products.json'
import { Lob } from '../api/types';
import { UserType } from '../store/system/types';

export const getAllCommercialProducts = () => {
    const activeProducts: CommercialProduct[] = [];
    CommercialProducts.items && (CommercialProducts.items.map((item, index) => {
        if (item.active) {
            activeProducts.push({ ...item, selected: false, disabled: false });
        }
    }));

    return activeProducts;
}

const getCommercialProductsByUser = (loginUserType: string, userRoles: string[]) => {

    const activeProducts: CommercialProduct[] = [];

    //  Filter Active Products
    CommercialProducts.items?.filter(p => p.active).forEach(product => {

        // When User is Agent check allowed roles
        if (loginUserType === UserType.Agent) {
            if (userRoles.find(role => product.agentRoles.includes(role))) {
                activeProducts.push({ ...product, selected: false, disabled: false });
            }
        }
        else {
             // Default User is Customer
            activeProducts.push({ ...product, selected: false, disabled: false });
        }
    });

    return activeProducts;
}

export const getSelectedCommercialProducts = (loginUserType: string, selectedLobs: Lob[], userRoles: string[] = [], sortSelected: boolean = true): CommercialProduct[] => {
    let cardProducts: CommercialProduct[] = [];

    var selectedProducts = getCommercialProductsByUser(loginUserType, userRoles).map(p => {
        if (selectedLobs.find(s => s.LobCode === p.lobCode)) {
            p.selected = true;
        }

        if (selectedLobs?.length > 0) {
            p.showDefault = true;
        }
        return p;
    })

    if (!sortSelected) {
        return selectedProducts
    }

    if (selectedLobs?.length > 0) {
        selectedProducts.forEach(s => {
            if (s.selected) {
                cardProducts.push(s);
            }
        });
    }
    selectedProducts.forEach(s => {
        if (!s.selected) {
            cardProducts.push(s);
        }
    });

    return cardProducts;
}

export const getLobNamesForAgents = (): string[] => {
    return CommercialProducts.items
        ?.filter(item => item.userType.some(userType => 
            userType.toLowerCase() === "agent" || userType.toLowerCase() === "agentupsert"))
        .map(item => item.lobName) || [];
}