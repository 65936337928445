import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid'; 
import { Alert, Button } from "../../components";
import { usePageLoadingContext } from "../../contexts/PageLoadingContext";
import { useSelector } from "react-redux";
import store, { RootState } from "../../store";
import { clearCache } from "../../store/quote/action";
import { useApi } from "../../api";
import { BusinessKeywordModel, BUSINESS_KEYWORD_RESPONSE, InitBusinessKeywordModel } from "../../api/types/business-keyword-model";
import { BusinessKeywordAutoComplete } from "../../components/business-keyword-autocomplete";
import { API_ERROR_MESSAGE } from "../../api/launchpad-api";
import { SaveQuoteRequest, SAVE_QUOTE_RESPONSE } from "../../api/types";
import {
    CarrierRecommendationModel,
    InitCarrierRecommendationModel,
    RecommendedCarrier,
    InitMultipleCarrierRecommendationModel,
    MULTIPLE_CARRIER_RECOMMENDATION_RESPONSE
} from "../../api/types/carrier-recommendation-model";

export const BusinessKeywordAgentUkw = () => {
    const api = useApi();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ title: "", message: "" });
    const quote = useSelector((state: RootState) => state.quote.quote);
    const { showLoader, hideLoader } = usePageLoadingContext();
    const [keywordError, setKeywordError] = useState("");
    const [primaryKeywordList, setPrimaryKeywordList] = useState<BusinessKeywordModel[]>([]);
    const [secondaryKeywordList, setSecondaryKeywordList] = useState<BusinessKeywordModel[]>([]);
    const [selectedPrimaryKeyword, setSelectedPrimaryKeyword] = useState<BusinessKeywordModel | null>(InitBusinessKeywordModel);
    const [selectedSecondaryKeyword, setSelectedSecondaryKeyword] = useState<BusinessKeywordModel | null>(InitBusinessKeywordModel);
    const [carrierRecommendation, setCarrierRecommendation] = useState<CarrierRecommendationModel>(InitCarrierRecommendationModel);
    const [universalKeyPairId, setUniversalKeyPairId] = useState<string>("");
    const [multipleCarrierRecommendation, setMultipleCarrierRecommendation] = useState<RecommendedCarrier[]>([]);
    const [selectedPartnerIndex, setSelectedPartnerIndex] = useState(-1);

    // Initialize the counter
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        showLoader();
        api.commercialAgentApi.getPrimaryBusinessKeywords().then((result) => {
            if (result.type === BUSINESS_KEYWORD_RESPONSE) {
                setPrimaryKeywordList(result.data);
            } else {
                window.scrollTo(0, 0);
                setShowAlert(true);
                setAlertMessage({
                    title: "Thank you for your request.",
                    message: API_ERROR_MESSAGE
                });
            }
            setShowAlert(false);
            hideLoader();
        }, (error) => {
            console.error(error);
            hideLoader();
        });
    }, []);

    useEffect(() => {
        if (selectedPrimaryKeyword && selectedPrimaryKeyword.id !== "") {
            showLoader();
            api.commercialAgentApi.getSecondaryBusinessKeywords(selectedPrimaryKeyword.id).then((result) => {
                if (result.type === BUSINESS_KEYWORD_RESPONSE) {
                    setSecondaryKeywordList(result.data);
                    setUniversalKeyPairId(result.data[0].id);
                    console.log("result data:", result.data);
                } else {
                    window.scrollTo(0, 0);
                    setShowAlert(true);
                    setAlertMessage({
                        title: "Thank you for your request.",
                        message: API_ERROR_MESSAGE
                    });
                }
                setShowAlert(false);
                hideLoader();
            }, (error) => {
                console.error(error);
                hideLoader();
            });
        } else {
            setSecondaryKeywordList([]);
        }
    }, [selectedPrimaryKeyword]);

    useEffect(() => {
        if (
            selectedPrimaryKeyword &&
            selectedPrimaryKeyword.id !== "" &&
            secondaryKeywordList.length > 0 &&
            secondaryKeywordList[0].description == null
        ) {
            setSelectedSecondaryKeyword({
                ...selectedPrimaryKeyword,
                id: universalKeyPairId
            });
        }
    }, [secondaryKeywordList]);

    useEffect(() => {
        if (selectedSecondaryKeyword && selectedSecondaryKeyword.id !== "") {
            setSelectedPartnerIndex(-1);
            setCarrierRecommendation(InitCarrierRecommendationModel);
            showLoader();

            let saveQuote: SaveQuoteRequest = {
                ...quote.data,
                BusinessKeyword: selectedPrimaryKeyword,
                BusinessSecondaryKeyword: selectedSecondaryKeyword,
                CarrierDetail: []
            };

            api.commercialAgentApi.getMultipleCarrierRecommendationUkw(saveQuote)
                .then((response) => {
                    hideLoader();
                    if (response.type === MULTIPLE_CARRIER_RECOMMENDATION_RESPONSE) {
                        // Increment the counter
                        setCounter(prevCounter => prevCounter + 1);

                        // Check if the counter is greater than 0 and update LaunchpadId
                        if (counter > 0) {
                            quote.data.LaunchpadId = uuidv4();
                        }

                        setMultipleCarrierRecommendation(response.data);
                    } else {
                        window.scrollTo(0, 0);
                        setShowAlert(true);
                        setMultipleCarrierRecommendation(InitMultipleCarrierRecommendationModel);
                        setAlertMessage({
                            title: "Thank you for your request.",
                            message: API_ERROR_MESSAGE
                        });
                    }

                }, (error) => {
                    console.log(error);
                    setShowAlert(true);
                    setMultipleCarrierRecommendation(InitMultipleCarrierRecommendationModel);
                    setAlertMessage({
                        title: "Thank you for your request.",
                        message: API_ERROR_MESSAGE
                    });
                    hideLoader();
                });
        } else {
            setMultipleCarrierRecommendation(InitMultipleCarrierRecommendationModel);
        }
    }, [selectedSecondaryKeyword]);

    const isNextButtonDisabled = (): boolean => {
        if (carrierRecommendation && carrierRecommendation.businessClass) {
            return false;
        }
        return true;
    };

    function clearAlertMessage() {
        setAlertMessage({
            title: "",
            message: ""
        });
    }

    function SubmitForm(): void {
        showLoader();
        let saveQuote: SaveQuoteRequest = {
            ...quote.data,
            BusinessKeyword: selectedPrimaryKeyword,
            BusinessSecondaryKeyword: selectedSecondaryKeyword,
            CarrierDetail: [{
                CarrierCob: carrierRecommendation.businessClass,
                CarrierCode: carrierRecommendation.carrier,
                CarrierName: carrierRecommendation.carrier?.toUpperCase(),
            }]
        };

        api.commercialAgentApi.saveQuote(saveQuote, true)
            .then((quoteResponse) => {
                if (quoteResponse.type !== SAVE_QUOTE_RESPONSE) {
                    window.scrollTo(0, 0);
                    setShowAlert(true);
                    setAlertMessage({
                        title: "Thank you for your request.",
                        message: API_ERROR_MESSAGE
                    });
                    hideLoader();
                    return;
                } else {
                    //quote.data.LaunchpadId = uuidv4();
                    window.open(quoteResponse.data.redirectUrl, "_blank"); // Open in a new tab
                    hideLoader();
                }
                setShowAlert(false);

            }, (error) => {
                setShowAlert(true);
                setAlertMessage({
                    title: "Thank you for your request.",
                    message: API_ERROR_MESSAGE
                });
                hideLoader();
            });
    }

    const OnParterSelect = (partner: RecommendedCarrier, index: number) => {
        setSelectedPartnerIndex(index);
        setCarrierRecommendation({
            businessClass: partner.isBoldPenguinOverride ? selectedSecondaryKeyword?.description ?? "" : partner.businessClass,
            carrier: partner.carrier
        });
    };

    const GetMultiplePartnerContent = () => {
        if (multipleCarrierRecommendation === null || multipleCarrierRecommendation?.length === 0)
            return null;

        return (
            <div className="row">
                <div className="col-sm-12">
                    <form>
                        <div id="partner_selection" className="image-selection-wrapper col-md-12 mb-3p5rem">
                            <fieldset className="form-field" role="radiogroup">
                                <label className={`text`}>Recommended partner(s) in preference order</label>
                                <p className="text-sm">(Left to right and top to bottom)</p>
                                <div className="image-selections">
                                    {
                                        multipleCarrierRecommendation.map((partner, index) => (
                                            <div key={index} onClick={() => OnParterSelect(partner, index)} className={
                                                index === selectedPartnerIndex
                                                    ? "image-selection-card image-selections-selected"
                                                    : "image-selection-card "} role="checkbox">
                                                <div className="image-selections-image">
                                                    <img
                                                        src={process.env.PUBLIC_URL + "/assets/partner-logos/" + `${partner.imageUrl}`}
                                                        alt={partner.label}
                                                        style={{ maxWidth: "80%", maxHeight: "80%", objectFit: "contain" }}
                                                    />
                                                </div>
                                                <label style={{ textAlign: "center", width: '100%' }}><b>{partner.label}</b></label>
                                            </div>))
                                    }
                                </div>
                            </fieldset>
                        </div>
                    </form>
                </div>
            </div>
        );
    };

    return (
        <div style={{ alignItems: "center", padding: "1rem", width: "95%" }} className="container">
            <div className="row">
                <div className="col-sm-12 col-md-12">
                    {(showAlert && (alertMessage.title || alertMessage.message) &&
                        <Alert
                            title={alertMessage.title}
                            content={alertMessage.message}
                            closeAlert={() => clearAlertMessage()}
                        />
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12">
                    <h2 className="page-header-left" style={{ padding: "1rem", marginBottom: "1rem" }}>
                        Tell us about your business.
                    </h2>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-12">
                    <BusinessKeywordAutoComplete
                        disabled={false}
                        label="What does your business do?"
                        placeholder="Example(s) contractor, painter etc"
                        type="text"
                        defaultValue={quote.data.BusinessKeyword?.id ?? ""}
                        suggestions={primaryKeywordList}
                        onItemChange={(selectedItem) => { setKeywordError(""); setSelectedPrimaryKeyword(selectedItem); setSelectedSecondaryKeyword(InitBusinessKeywordModel); }}
                        error={keywordError}
                    />
                </div>
            </div>
            {selectedPrimaryKeyword && selectedPrimaryKeyword.id && secondaryKeywordList.length > 0 && secondaryKeywordList[0].description !== null && (
                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        <BusinessKeywordAutoComplete
                            disabled={selectedPrimaryKeyword.id === ""}
                            label="Please give us a little more information about what your business does."
                            placeholder="Example(s) Non Residential, Industrial etc"
                            type="text"
                            defaultValue={quote.data.BusinessKeyword?.id ?? ""}
                            suggestions={secondaryKeywordList}
                            onItemChange={(selectedItem) => { setKeywordError(""); setSelectedSecondaryKeyword(selectedItem); }}
                            error={keywordError}
                        />
                    </div>
                </div>
            )}
            {GetMultiplePartnerContent()}
            <div className="row mt-10">
                <div className="col-sm-12 col-md-6">
                    <Button style={{ display: "none" }}
                        className="btn--secondary nav-button-back mt-10 w-3/5 xs:w-full sm:w-full"
                        onClick={(e) => window.history.go(-1)}
                    >Back</Button>
                </div>

                <div className="col-sm-12 col-md-6">
                    <Button disabled={isNextButtonDisabled()}
                        className="btn--primary nav-button-next mt-10  w-3/5 xs:w-full sm:w-full"
                        onClick={(e) => SubmitForm()}
                    >Continue</Button>
                </div>
            </div>
        </div>
    );
};